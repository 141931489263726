*{
    margin:0px 0px 0px 0px;
    box-sizing: border-box;
    padding:0px 0px 0px 0px;
    scroll-behavior: smooth;
}
.MuiButton-containedPrimary:hover{background-color: transparent !important;}
body{
    font-family: 'Lato', sans-serif;
    overflow-y: scroll; scroll-behavior:smooth;
}
a{
    text-decoration:none;
}
h1, h2, h3, h4, h5, h6, p, ul{
    margin:0px 0px 0px 0px;
    padding:0px 0px 0px 0px 0px;
}
.small-heading{
    font-size:14px;
    line-height:21px;
}
.page-404-head{
    font-size:87px;
    line-height:82px;
    letter-spacing:0px; 
}
.h1-heading{
    font-size:36px;
    line-height:45px;
    letter-spacing:0px; 
}

.h2-heading{
    font-size:30px;
    line-height:37.5px;
    letter-spacing:0px; 
}
.h3-heading{
    font-size:24px;
    line-height:30px;
    letter-spacing:0px; 
}
.h4-heading{
    font-size:20px;
    line-height:30px;
    letter-spacing:0px; 
}
.subtitle2{
    font-size:14px;
    line-height:21px;
    letter-spacing:0px;
 }
 .list-subtitle {
    font-size:12px;
    line-height:18px;
    letter-spacing:0px;
 }

.subtitle{
   font-size:16px;
   line-height:24px;
   letter-spacing:0px;
}
/* font weight  classes start */
.font-bold{
    font-weight:700;
}
.font-black{
    font-weight:900;
}
.font-regular{
    font-weight:400;
}
/* font weight  classes start */


/* padding  classes start */
.margin-top-3{margin-top:-4px;}
.padding-bottom-16{
    padding-bottom:16px;
}
.padding-bottom-30{
    padding-bottom:30px;
}
.padding-bottom-36{
    padding-bottom:36px;
}
.padding-bottom-20{
    padding-bottom:20px;
}

.padding-bottom-14{
    padding-bottom:14px;
}
.section-container{
    padding-top:128px;
    padding-bottom:128px;
}
.section-padding-top{
    padding-top:128px;
}
.section-padding-bottom{
    padding-bottom:128px;
}
.padding-top-bottom-60{
    padding-top:60px;
    padding-bottom:60px;
}
.padding-top-96{
    padding-top:96px;
}
.padding-bottom-96{
    padding-bottom:96px;
}
.padding-bottom-24{
    padding-bottom:24px;
}

.padding-top-bottom-60{
    padding-top:60px;
    padding-bottom:60px;
}
.padding-bottom-9{padding-bottom: 9px;}
.padding-bottom-60{
    padding-bottom:60px;
}
/* padding classes end */

/* margin class start */
.margin-bottom-20{
    margin-bottom:20px;
}
.margin-bottom-16{
    margin-bottom:16px;
}
.margin-bottom-10{
    margin-bottom:10px;
}
.margin-bottom-124{
    margin-bottom:128px;
}
/* margin classes end */

/* color classes start */
.text-green{
color:#45BEA0;
}
.text-blue{
    color:#1D343E;
}
.text-blue-faint{
    color:#2E5668;
}
.text-blue-dark{
    color:#181633;
}
.text-white{
    color:#fff;
}

/* color classes start */

.center-subheading{
    max-width:590px;
}
.values .center-subheading{
    max-width:620px;
}
.text-center{
    text-align:center;
}

/* button classes start */
.MuiButtonBase-root.rounded-white-btn {
    border-radius: 21px 21px 21px 21px;
    color: #1D343E;
    font-size: 14px;
    line-height: 21px;
    background: #ffffff;
    padding: 12px 32px;
    width: max-content;
    font-weight:bold;
    cursor: pointer;
    text-transform:unset;
    font-family: 'Lato', sans-serif;
}
.MuiButtonBase-root.rounded-white-btn span{
    z-index: 9;
    display: block;
    letter-spacing:0;
    font-family: 'Lato', sans-serif;
}
/* .MuiButtonBase-root.rounded-white-btn:hover {
    color:#fff;
    background: #56E3C9;
    transform: scale(1.04,1.1);
} */
.MuiButtonBase-root.rounded-white-btn:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 100px;
    z-index: 0;
    transform: scale(1);
    transition: all .2s ease;
}
.MuiButtonBase-root.rounded-white-btn:hover:after {
    background: #63CCB5;
    transform: scale(1.04,1.1);
}
/* button classes end */
/* header css start */
.headerContainer {
    height: 70px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 100;
    background-color: white
}

header{
    display: block;
    width: 100%;
    /* padding: 11px 0px; */
}
header .site-logo{
    display: inline-block;
}
header ul{
    list-style: none;
    display: inline-block;
    float: right;
    height: 100%;
    padding: 10px 0px;
}
header ul li {
    display: inline-block;
    padding-left: 36px;
    font-weight: bold;
    cursor: pointer;
}
.link-animation:hover:after {
    width: 100%;
    right: auto;
    left: 0;
}
.link-animation:after {
    height: 1px;
    width: 0;
    background: #45BEA0;
    content: "";
    position: absolute;
    right: 0;
    bottom: -2px;
    transition: .7s all;
}
.link-animation{
    position:relative;
}
.link-animation:hover{
    color:#45BEA0;
    text-decoration:none;
}
.link-animation.active{
    color:#45BEA0;
    text-decoration:none;
}
.link-animation.active:after {
    width: 100%;
    right: auto;
    left: 0;
}
.header-logo a{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.mobile-menu-icon{
    display:none;
}
/* header css end */

/*footer css*/
.footer{background-color: #EAEEF0;}
.footer .MuiGrid-container{justify-content:space-between;}
.svg-icon-twitter{background-color: #45BEA0;
    fill:#fff !important;
    font-size: 30px !important;
    border-radius: 50% !important;}
    .footer ul{list-style: none;}
    .footer ul li{list-style: none; line-height: 36px; color:#1D343E;}
    .footer ul li a{ color:#1D343E;}
    .footer .textfield{border-radius: 21px; background-color: #fff; padding: 15px;
        border: none; width: 100%;}
        .footer-border{border-bottom: 0.5px solid #829AA4; margin-top:60px; }
        .sub-footer{padding-top: 20px;}
        .sub-footer span{padding-right: 15px;}
        .sub-footer span a{color:#1D343E}
        .copyright{text-align: right;}
        .MuiButtonBase-root.footer-btn {
            border-radius: 21px 21px 21px 21px;
            color: #1D343E;
            font-size: 14px;
            line-height: 21px;
            background: #45BEA0;
            padding: 12px 32px;
            width: max-content;
            font-weight:bold;
            cursor: pointer;
            text-transform:unset;
            font-family: 'Lato', sans-serif;
        }
        .MuiButtonBase-root.footer-btn span{
            z-index: 9;
            display: block;
        }
        .MuiButtonBase-root.footer-btn:after {
           content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 100px;
            z-index: 0;
            transform: scale(1);
            transition: all .2s ease;
        }
        .MuiButtonBase-root.footer-btn:hover:after {
            background: #ffffff;
            transform: scale(1.04,1.1);
        }
        .footer-social-media {
            width: 30px;
            height: 30px;
            background: #45BEA0;
            border-radius: 50%;
            padding: 7px;
            display: inline-flex;
            margin-right: 10px;
            justify-content: center;
        }
        .footer-social-media svg{
          width:100%;
          height:100%;
            color: #ffffff;
        }      
        .footerlogo-img{
            width:109px;
            height:35px;
        }    
        .footer  .gird{display: contents;}
/* end footer css*/

.CommonBanner{
    position:relative;
    width:100%;
    color:#fff;
   /* overflow:hidden;
    max-height: 389px;*/
   
}
.CommonBanner .slick-slider{max-width: 100% !important; padding: 0px !important;}
.CommonBannerImg img{
    width:100%;   
    
}
.CommonBannerContent {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.custom-container{
    max-width:1170px;
    margin-left:auto;
    margin-right:auto;
}   

.CommonBannerContent p{
    max-width:488px;
    font-weight:normal;
}


/*---------------------------------Mobile View Start-----------------------------------------------*/
@media (max-width:767px) {
    .h1-heading{
        font-size:30px;
        line-height:37.5px;
        letter-spacing:0px; 
    }
    .h2-heading{
        font-size:24px;
        line-height:30px;
        letter-spacing:0px; 
    }
    .page-404-head{
        font-size:40px;
        line-height:42px;
        letter-spacing:0px; 
    }
      /* header css start */
      .mobile-menu-icon {
        display: block;
        float: right;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .mobile-menu-icon svg{
        fill:#45BEA0;
    }
    .desktop-menu{
        display:none;
    }
    .header-logo img{
        max-width:max-content;
    }
    /* header css end */

    .section-container{
        padding-top:72px;
        padding-bottom:72px;
        padding-left:15px;
        padding-right:15px;
    }
    .custom-container{
        padding-left:25px;
        padding-right:25px;
    }
    .CommonBannerImg img {
        max-height: 400px;
        min-height: 400px;
        width: inherit;
        object-fit: cover;
    }
    .section-padding-top{
        padding-top:72px;
    }
    .margin-bottom-124{
        margin-bottom:72px;
    }
    .section-padding-bottom{
        padding-bottom:72px;
    }
    .padding-top-96{
        padding-top:60px;
    }
    .padding-bottom-96{
        padding-bottom:60px;
    }
   
/* footer Start */
.footer ul li a:hover{ 
    color:#45BEA0;
}
.footer .padding-bottom-24 {
        padding-bottom: 16px;
    }
.padding-bottom-30 {
      padding-bottom: 20px;
   }
.footer-first-div{
    padding-top: 30px;
}
.sub-footer{
    text-align:center !important;
}
.copyright {
    text-align: center;
    padding-top:10px;
}
.footer .MuiGrid-container{display: block;}
  /* footer End */

}

/*---------------------------------Mobile View End-----------------------------------------------*/

/*-------------------------Ipad View Start-------------------------------------------------------*/

@media (min-width:768px) and (max-width:1023px) {
    .section-container{
        padding-top:72px;
        padding-bottom:72px;
        padding-left:15px;
        padding-right:15px;
    }
    .custom-container{
        padding-left:20px;
        padding-right:20px;
    }
    .CommonBannerImg img {
        width: inherit;
        max-height: 400px;
        min-height: 400px;
    }
    .section-padding-top{
        padding-top:72px;
    }
    .margin-bottom-124{
        margin-bottom:72px;
    }
    .section-padding-bottom{
        padding-bottom:72px;
    }
    .padding-top-96{
        padding-top:60px;
    }
    .padding-bottom-96{
        padding-bottom:60px;
    }
    /* footer start */
    .footer .ipad{padding-left: 23px;}
    /* footer end */
    /* header start */
    header>ul>li {
        padding-left: 24px;
    }
      /* header end */
}
/*-------------------------Ipad View End---------------------------------------------------------*/




/*-------------------------Ipad Pro View Start-----------------------------------*/

@media (min-width: 1024px) and (max-width: 1180px) { 
    .section-container{
        padding-top:72px;
        padding-bottom:72px;
        padding-left:15px;
        padding-right:15px;
    }
    .custom-container{
        padding-left:20px;
        padding-right:20px;
    }
    .CommonBannerImg img {
        width: inherit;
        max-height: 400px;
        min-height: 400px;  
    }
    .section-padding-top{
        padding-top:72px;
    }
    .section-padding-bottom{
        padding-bottom:72px;
    }
    .padding-top-96{
        padding-top:60px;
    }
    .padding-bottom-96{
        padding-bottom:60px;
    }
     /* header start */
    header>ul>li {
        padding-left: 24px;
    }
     /* header send */
}

/*-------------------------Ipad Pro View End-----------------------------------*/