/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
  --color-dori-blue: #2E5668;
  --color-dori-font-black: #555;
  --color-dori-blue-white-gradient-minus45: linear-gradient(-45deg, #2E5668 0%, #000 100%);
  --color-dori-blue-gradient-minus45: linear-gradient(-45deg, #2E5668ef 0%, #000000ef 100%), url(../img/call-to-action-bg.jpg) fixed center center;
  --color-dori-solutions-header: linear-gradient(-45deg, #2E5668aa, #000000aa), url(../img/call-to-action-bg.jpg) fixed center center;
  --color-dori-solutions-bg: linear-gradient(-45deg, #2E5668aa, #000000aa), url(../img/call-to-action-bg.jpg) fixed center center;
  --color-dori-bg: linear-gradient(-45deg, #2E5668aa, #000000aa), url(../img/call-to-action-bg.jpg) fixed center center;

}

@font-face{
  font-family: "Montserrat";
  src: url("https://www.dori.ai/css/fonts/montserrat/Montserrat-Black.otf") format("opentype"),
    url("https://www.dori.ai/css/fonts/montserrat/Montserrat-Bold.otf") format("opentype"),
    url("https://www.dori.ai/css/fonts/montserrat/Montserrat-ExtraBold.otf") format("opentype"),
    url("https://www.dori.ai/css/fonts/montserrat/Montserrat-Medium.otf") format("opentype"),
    url("https://www.dori.ai/css/fonts/montserrat/Montserrat-Regular.otf") format("opentype"),
    url("https://www.dori.ai/css/fonts/montserrat/Montserrat-SemiBold.otf") format("opentype"),
}


body {
  background: #fff;
  color: var(--color-dori-font-black);
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

a {
  color: var(--color-dori-blue);
  transition: 0.5s;
}

a:hover, a:active, a:focus {
  color: #1dc9ce;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 30px 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}

/* Back to top button */
.back-to-top {
  position: fixed;
  display: none;
  background: var(--color-dori-blue);
  color: #fff;
  padding: 2px 20px 8px 20px;
  font-size: 14px;
  border-radius: 4px 4px 0 0;
  right: 15px;
  bottom: 0;
  transition: none;
}

.back-to-top:focus {
  background: var(--color-dori-blue);
  color: #fff;
  outline: none;
}

.back-to-top:hover {
  background: var(--color-dori-blue);
  color: #fff;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  padding: 30px 0;
  height: 92px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 997;
/*  background: var(--color-dori-solutions-header);*/
  color: #fff;
}

#header #logo {
  float: left;
}

#header #logo h1 {
  font-size: 36px;
  margin: -4px 0 0 0;
  padding: 0;
  line-height: 1;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header #logo h1 a, #header #logo h1 a:hover {
  color: #fff;
}

#header #logo img {
  padding: 0;
  margin: 0;
}

#header a {
  font-family: "Open Sans",sans-serif;
  font-size: 12px;
}

@media (max-width: 768px) {
  #header #logo h1 {
    font-size: 28px;
    margin-top: 0;
  }
  #header #logo img {
  }
}

#header.header-fixed {
  background: var(--color-dori-solutions-bg);
  padding: 20px 0;
  height: 82px;
  transition: all 0.25s;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
}

#header.header-fixed a {
  color: #fff;
}

#logo2 {
  display: none;
}

#header.header-absolute #logo {
  display: inline-block;
}

#header.header-absolute #logo2 {
  display: none;
}

#header.header-fixed #logo {
  display: none;
}

#header.header-fixed #logo2 {
  display: inline-block;
}


/*--------------------------------------------------------------
# Intro Section
--------------------------------------------------------------*/

/* Animations */
@keyframes rotating-function {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade-slide-down {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#intro {
  position: relative;
  background: var(--color-dori-solutions-header);
/*  background: #fff;*/
  background-size: cover;
}

#intro .intro-text {
  position: relative;
  padding-top: 150px;
  display: flex;
  justify-content: left;
}

#intro .intro-subtitle {
  font-size: 18px;
  color: "white";
  text-align: center;
  font-weight: 600;
}

#intro .intro-diagrams {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  width: -webkit-fill-available;
}

#intro h1 {
  font-weight: 500;
  color: #fff;
}

#intro h2 {
  margin: 30px 0 10px 0;
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
  color: #fff;
}

@media (max-width: 768px) {
  #intro h2 {
    font-size: 28px;
    line-height: 36px;
  }
}

#intro p {
  color: #fff;
  margin-bottom: 20px;
  font-size: 18px;
}

@media (max-width: 768px) {
  #intro p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}

#intro .btn-get-started {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

#intro .btn-get-started:hover {
  color: var(--color-dori-blue);
  background: #fff;
}


/*--------------------------------------------------------------
# Intro Platform Section
--------------------------------------------------------------*/

/* Animations */
@keyframes rotating-function {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade-slide-down {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#intro-platform {
  position: relative;
  background: var(--color-dori-solutions-header);
/*  background: #fff;*/
  background-size: cover;
}

#intro-platform .intro-text {
  position: relative;
  padding-top: 150px;
  display: flex;
  justify-content: left;
}

#intro-platform .intro-subtitle {
  font-size: 18px;
  color: "white";
  text-align: center;
  font-weight: 600;
}

#intro-platform .intro-diagrams {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  width: -webkit-fill-available;
}

#intro-platform h1 {
  font-size: 30px;
  font-weight: 700;
  color: #eee;
}

#intro-platform h2 {
  font-size: 30px;
  font-weight: 700;
  color: #eee;
}

#intro-platform h3 {
  font-size: 24px;
  font-weight: 700;
  color: white;
}

#intro-platform ul li {
  color: #eee;
  font-size: 16px;
}

@media (max-width: 768px) {
  #intro-platform h2 {
    font-size: 28px;
    line-height: 36px;
  }
}

#intro-platform p {
  color: #eee;
  margin-bottom: 20px;
  font-size: 16px;
}

@media (max-width: 768px) {
  #intro-platform p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}

#intro-platform .btn-get-started {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

#intro-platform .btn-get-started:hover {
  color: var(--color-dori-blue);
  background: #fff;
}

/*--------------------------------------------------------------
# Intro Why Dori Section
--------------------------------------------------------------*/

#intro-whydori {
  position: relative;
  background: var(--color-dori-solutions-header);
/*  background: #fff;*/
  background-size: cover;
}

#intro-whydori .intro-text {
  position: relative;
  padding-top: 150px;
  display: flex;
  justify-content: left;
}

#intro-whydori .intro-subtitle {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 20px;
  color: #fff;
}

#intro-whydori .intro-diagrams {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  width: -webkit-fill-available;
}


#intro-whydori h1 {
  font-size: 30px;
  font-weight: 700;
  color: #eee;
}

#intro-whydori h2 {
  font-size: 24px;
  font-weight: 700;
  color: white;
}

#whydori h3 {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-dori-blue);
}

#intro-whydori h4 {
  margin-left: 100px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
  color: var(--color-dori-font-black);
}

#intro-whydori ul li {
  color: #fff;
  font-size: 16px;
}

@media (max-width: 768px) {
  #intro-whydori h2 {
    font-size: 28px;
    line-height: 36px;
  }
}

#intro-whydori p {
  color: #eee;
  margin-bottom: 20px;
  font-size: 16px;
}

@media (max-width: 768px) {
  #intro-whydori p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}

#intro-whydori .btn-get-started {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

#intro-whydori .btn-get-started:hover {
  color: var(--color-dori-blue);
  background: #fff;
}

#intro-whydori .box {
  padding-top: 20px;
/*margin-bottom: 30px;   */
  background: transparent;
  transition: 0.4s;
  padding-bottom: 20px;
}

#intro-whydori .box p {
  color: white;
  text-align: center;
}

#intro-whydori .box h4 {
  color: white;
}



/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Nav Menu Essentials */
.nav-menu, .nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99;
}

.nav-menu li {
  position: relative;
  white-space: nowrap;
}

.nav-menu > li {
  float: left;
}

.nav-menu li:hover > ul,
.nav-menu li.sfHover > ul {
  display: block;
}

.nav-menu ul ul {
  top: 0;
  left: 100%;
}

.nav-menu ul li {
  min-width: 180px;
}

/* Nav Menu Arrows */
.sf-arrows .sf-with-ul {
  padding-right: 30px;
}

.sf-arrows .sf-with-ul:after {
  content: "\f107";
  position: absolute;
  right: 15px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

.sf-arrows ul .sf-with-ul:after {
  content: "\f105";
}

/* Nav Meu Container */
#nav-menu-container {
  float: right;
  margin: 0;
}

@media (max-width: 768px) {
  #nav-menu-container {
    display: none;
  }
}

/* Nav Meu Styling */
.nav-menu a {
  padding: 10px 8px 10px 8px;
  text-decoration: none;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  outline: none;
  color: #fff}

.nav-menu > li {
  margin-left: 10px;
}

.nav-menu ul {
  margin: 4px 0 0 0;
  padding: 10px;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  background: #fff;
}

.nav-menu ul li {
  transition: 0.3s;
}

.nav-menu ul li a {
  padding: 10px;
  color: #333;
  transition: 0.3s;
  display: block;
  font-size: 14px;
  text-transform: none;
}

.nav-menu ul li:hover > a {
  color: var(--color-dori-blue);
}

.nav-menu ul ul {
  margin: 0;
}

/* Mobile Nav Toggle */
#mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  margin: 20px 20px 0 0;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
  cursor: pointer;
}

#mobile-nav-toggle i {
  color: #eee;
}

@media (max-width: 768px) {
  #mobile-nav-toggle {
    display: inline;
  }
}

/* Mobile Nav Styling */
#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: rgba(52, 59, 64, 0.9);
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s;
}

#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#mobile-nav ul li {
  position: relative;
}

#mobile-nav ul li a {
  color: #fff;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
}

#mobile-nav ul li a:hover {
  color: #fff;
}

#mobile-nav ul li li {
  padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: var(--color-dori-blue);
}

#mobile-nav ul .menu-item-active {
  color: var(--color-dori-blue);
}

#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(52, 59, 64, 0.9);
  display: none;
}

/* Mobile Nav body classes */
body.mobile-nav-active {
  overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
  left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
  color: #fff;
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
/* Sections Header
--------------------------------*/
.section-header .section-title {
  font-size: 32px;
  color: var(--color-dori-blue);
  text-align: center;
  font-weight: 400;
}

.section-header .section-description {
  text-align: center;
  padding-bottom: 40px;
  color: var(--color-dori-font-black);
  font-style: normal;
}

.section-header .section-divider {
  display: block;
  width: 60px;
  height: 2px;
  background: var(--color-dori-blue);
  margin: 0 auto;
  margin-bottom: 20px;
}

.section-dark-header .section-title {
  font-size: 32px;
  color: #fff;
  text-align: center;
  font-weight: 400;
}

.section-dark-header .section-description {
  text-align: center;
  padding-bottom: 40px;
  color: var(--color-dori-font-black);
  font-style: normal;
}

.section-dark-header .section-divider {
  display: block;
  width: 60px;
  height: 3px;
  background: #fff;
  margin: 0 auto;
  margin-bottom: 20px;
}

/* Section with background
--------------------------------*/
.section-bg {
  /*background: #eff5f5;*/
  background: #fff;
}



/* Call To Action Section
--------------------------------*/
#call-to-action {
  background: linear-gradient(rgba(29, 200, 205, 0.65), rgba(29, 205, 89, 0.2)), url(../img/call-to-action-bg.jpg) fixed center center;
  background-size: cover;
  padding: 80px 0;
}

#call-to-action .cta-title {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

#call-to-action .cta-text {
  color: #fff;
}

@media (min-width: 769px) {
  #call-to-action .cta-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

#call-to-action .cta-btn {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 30px;
  border-radius: 25px;
  transition: background 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

#call-to-action .cta-btn:hover {
  background: var(--color-dori-blue);
  border: 2px solid #2E5668;
}

/* Features Section
--------------------------------*/
#features {
  padding: 60px 0 0px 0;
  /*background: var(--color-dori-blue-white-gradient-minus45);*/
  background: var(--color-dori-solutions-bg);
}

#features .box {
  padding-top: 20px;
/*margin-bottom: 30px;   */
  background: transparent;
  transition: 0.4s;
  padding-bottom: 20px;
}

#features .box p {
  color: white;
}

#features .box h4 {
  color: white;
}

#features .box2 {
  padding-top: 10px;
/*margin-bottom: 30px;   */
  background: #eee;
  transition: 0.4s;
  padding-bottom: 10px;
}

#features .icon {
  float: left;
}

#features .icon i {
  color: #666666;
  font-size: 80px;
  transition: 0.5s;
  line-height: 0;
}

#features .description {
  color: var(--color-dori-blue);
  margin: 0px;
  text-align: center;
  font-size: 16px;
}

#features .icon i:before {
  background: var(--color-dori-blue);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#features ul li {
  color: #fff;
  font-size: 16px;
}

#features h1 {
  font-size: 30px;
  font-weight: 700;
  color: #eee;
}

#features h2 {
  font-size: 24px;
  font-weight: 700;
  color: white;
}

#features h4 {
  margin-left: 100px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
  color: #fff;
}

#features h4 a {
  color: #111;
}

#features p {
  font-size: 16px;
  margin-left: 100px;
  margin-bottom: 0;
  line-height: 24px;
  color: #eee
}

@media (max-width: 767px) {
  #features .box {
    margin-bottom: 20px;
  }
  #features .icon {
    float: none;
    text-align: center;
    padding-bottom: 15px;
  }
  #features h4, #features p {
    margin-left: 0;
    text-align: center;
  }
}


/* Solutions Section
--------------------------------*/
#solutions {
  padding: 60px 0 0px 0;
  background-image: linear-gradient(90deg, #fff, #2E566888), url(../img/brain_blue.svg);
  background-position: 800px 0px;
  background-repeat: repeat-y;
/*background: linear-gradient(to right, #2E5668 0%, #fff 10% 90%, #2E5668 100%)   */
}


#solutions .box {
  padding-top: 20px;
/*margin-bottom: 30px;   */
  background: transparent;
  transition: 0.4s;
  padding-bottom: 20px;
  border: solid;
  border-radius: 10px;
  border-color: #2E5668;
  border-width: 2px;
}

#solutions .box p {
/*color: white;   */
  margin:0px;
  text-align:center;
}

#solutions .box h4 {
  color: var(--color-dori-blue);
}

#solutions .box2 {
  padding-top: 10px;
/*margin-bottom: 30px;   */
  background: #eee;
  transition: 0.4s;
  padding-bottom: 10px;
}

#solutions .icon {
  float: left;
}

#solutions .icon i {
  color: #666666;
  font-size: 80px;
  transition: 0.5s;
  line-height: 0;
}

#solutions .icon i:before {
  background: var(--color-dori-blue);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#solutions h1 {
  font-size: 30px;
  font-weight: 700;
  color: #333;
}

#solutions h2 {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-dori-blue);
}

#solutions h4 {
  margin-left: 100px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
}

#solutions h4 a {
  color: #111;
}

#solutions p {
  font-size: 16px;
  margin-bottom: 0;
  line-height: 24px;
  color: var(--color-dori-font-black);
}

@media (max-width: 767px) {
  #solutions .box {
    margin-bottom: 20px;
  }
  #solutions .icon {
    float: none;
    text-align: center;
    padding-bottom: 15px;
  }
  #solutions h4, #platform p {
    margin-left: 0;
    text-align: center;
  }
}

/* Section Dori Blue
--------------------------------*/
#section-dori-blue {
  padding: 0px 0 0px 0;
  background: var(--color-dori-blue-gradient-minus45);
/*background: var(--color-dori-blue-white-gradient-minus45);   */
/*  background: var(--color-dori-solutions-bg);*/
}

#section-dori-blue .section-header .section-title {
  color: #fff;
}

#section-dori-blue .box {
  padding-top: 20px;
/*margin-bottom: 30px;   */
  background: #eee;
  transition: 0.4s;
  padding-bottom: 20px;
}

#section-dori-blue .box2 {
  padding-top: 10px;
/*margin-bottom: 30px;   */
  background: #eee;
  transition: 0.4s;
  padding-bottom: 10px;
}

#section-dori-blue .icon {
  float: left;
}

#section-dori-blue .icon i {
  color: #666666;
  font-size: 80px;
  transition: 0.5s;
  line-height: 0;
}

#section-dori-blue .icon i:before {
  background: var(--color-dori-blue);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#section-dori-blue h2 {
  font-size: 24px;
  font-weight: 700;
  color: "white"
}

#section-dori-blue h4 {
  margin-left: 0px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
}

#section-dori-blue h4 a {
  color: #fff;
}

#section-dori-blue p {
  font-size: 16px;
  margin-left: 0px;
  margin-bottom: 0;
  line-height: 24px;
  color: #eee
}

#section-dori-blue .section-divider {
  background: #fff;
}

@media (max-width: 767px) {
  #section-dori-blue .box {
    margin-bottom: 20px;
  }
  #section-dori-blue .icon {
    float: none;
    text-align: center;
    padding-bottom: 15px;
  }
  #section-dori-blue h4, #section-dori-blue p {
    margin-left: 0;
    text-align: center;
  }
}

#section-dori-blue ul li {
  font-size: 16px;
  color: #eee;
}


/* Section Dori White
--------------------------------*/
#section-dori-white {
  padding: 0px 0 0px 0;
/*background: #fff;   */
/*background: linear-gradient(to right, #2E5668 0%, #fff 10% 90%, #2E5668 100%);   */
  background-image: linear-gradient(90deg, #fff, #2E566888), url(../img/brain_blue.svg);
  background-position: 900px 0px;
  background-repeat: repeat-y;
/*background-image: linear-gradient(90deg, #fff 80%, #2E566888), url(../img/brain_blue.svg);   */
}

#section-dori-white .box {
  padding: 40px;
  margin-bottom: 30px;
  background: #FFF;
  transition: 0.4s;
}

#section-dori-white .boxinvisible {
  padding: 40px;
  margin-bottom: 30px;
  background: #eeeeee;
  transition: 0.4s;
  display: flex;
  align-content: center;
  justify-content: center;
}

#section-dori-white .icon {
  float: left;
}

#section-dori-white .icon i {
  color: #666666;
  font-size: 80px;
  transition: 0.5s;
  line-height: 0;
}

#section-dori-white .icon i:before {
  background: var(--color-dori-blue);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#section-dori-white h2 {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-dori-blue);
}

#section-dori-white h4 {
  margin-left: 100px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
}

#section-dori-white h4 a {
  color: #111;
}

#section-dori-white p {
  font-size: 16px;
/*margin-left: 100px;   */
  margin-bottom: 0;
  line-height: 24px;
  color: var(--color-dori-font-black);
}

#section-dori-white .section-divider {
  background: var(--color-dori-blue);
}

#section-dori-white .title {
  color: #111;
}

@media (max-width: 767px) {
  #section-dori-white .box {
    margin-bottom: 20px;
  }
  #section-dori-white .icon {
    float: none;
    text-align: center;
    padding-bottom: 15px;
  }
  #section-dori-white h4, #section-dori-white p {
    margin-left: 0;
    text-align: center;
  }
}

#section-dori-white ul li {
  font-size: 16px;
  color: var(--color-dori-font-black);
}


/* Platform Section
--------------------------------*/
#platform {
  padding: 60px 0 0px 0;
  background-image: linear-gradient(90deg, #fff, #2E566888), url(../img/brain_blue.svg);
  background-position: 800px 0px;
  background-repeat: repeat-y;
}

#platform .box {
  padding: 40px;
  margin-bottom: 30px;
  background: #FFF;
  transition: 0.4s;
}

#platform .boxinvisible {
  padding: 40px;
  margin-bottom: 30px;
  background: #eeeeee;
  transition: 0.4s;
  display: flex;
  align-content: center;
  justify-content: center;
}

#platform .icon {
  float: left;
}

#platform .icon i {
  color: #666666;
  font-size: 80px;
  transition: 0.5s;
  line-height: 0;
}

#platform .icon i:before {
  background: var(--color-dori-blue);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#platform h1 {
  font-size: 30px;
  font-weight: 700;
  color: #333;
}

#platform h2 {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-dori-blue);
}

#platform h4 {
  margin-left: 100px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
}

#platform h4 a {
  color: #111;
}

#platform p {
  font-size: 16px;
/*margin-left: 100px;   */
  margin-bottom: 0;
  line-height: 24px;
  color: var(--color-dori-font-black);
}

@media (max-width: 767px) {
  #platform .box {
    margin-bottom: 20px;
  }
  #platform .icon {
    float: none;
    text-align: center;
    padding-bottom: 15px;
  }
  #platform h4, #platform p {
    margin-left: 0;
    text-align: center;
  }
}

/* Platform Section
--------------------------------*/
#platform-product {
  padding: 0px 0 0px 0;
  background-image: linear-gradient(90deg, #fff, #2E566888), url(../img/brain_blue.svg);
  background-position: 900px 0px;
  background-repeat: repeat-y;
/*background-image: linear-gradient(90deg, #fff 80%, #2E566888), url(../img/brain_blue.svg);   */
/*background: linear-gradient(to right, #2E5668 0%, #fff 10% 90%, #2E5668 100%)   */
}

#platform-product .box {
  padding: 40px;
  margin-bottom: 30px;
  background: #FFF;
  transition: 0.4s;
}

#platform-product .boxinvisible {
  padding: 40px;
  margin-bottom: 30px;
  background: #eeeeee;
  transition: 0.4s;
  display: flex;
  align-content: center;
  justify-content: center;
}

#platform-product .icon {
  float: left;
}

#platform-product .icon i {
  color: #666666;
  font-size: 80px;
  transition: 0.5s;
  line-height: 0;
}

#platform-product .icon i:before {
  background: var(--color-dori-blue);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#platform-product h1 {
  font-size: 30px;
  font-weight: 700;
  color: #333;
}

#platform-product h2 {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-dori-blue);
}

#platform-product h4 {
  margin-left: 100px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
}

#platform-product h4 a {
  color: #111;
}

#platform-product p {
  font-size: 16px;
  margin-left: 100px;
  margin-bottom: 0;
  line-height: 24px;
  color: var(--color-dori-font-black);
}

@media (max-width: 767px) {
  #platform-product .box {
    margin-bottom: 20px;
  }
  #platform-product .icon {
    float: none;
    text-align: center;
    padding-bottom: 15px;
  }
  #platform-product h4, #platform p {
    margin-left: 0;
    text-align: center;
  }
}

#platform-product ul li {
  font-size: 16px;
}


/* Why Dori Section
--------------------------------*/
#whydori {
  padding: 60px 0 0px 0;
  background-image: linear-gradient(90deg, #fff, #2E566888), url(../img/brain_blue.svg);
  background-position: 800px 0px;
  background-repeat: repeat-y;
/*background: linear-gradient(to right, #2E5668 0%, #fff 10% 90%, #2E5668 100%)   */
}

#whydori .box {
  padding: 40px;
  margin-bottom: 30px;
  background: #FFF;
  transition: 0.4s;
}

#whydori .boxinvisible {
  padding: 40px;
  margin-bottom: 30px;
  background: #eeeeee;
  transition: 0.4s;
  display: flex;
  align-content: center;
  justify-content: center;
}

#whydori .icon {
  float: left;
}

#whydori .icon i {
  color: #666666;
  font-size: 80px;
  transition: 0.5s;
  line-height: 0;
}

#whydori .icon i:before {
  background: var(--color-dori-blue);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#whydori h1 {
  font-size: 30px;
  font-weight: 700;
  color: #333;
}

#whydori h2 {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-dori-blue);
}

#whydori h4 {
  margin-left: 100px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
}

#whydori h4 a {
  color: #111;
}

#whydori p {
  font-size: 16px;
/*margin-left: 100px;   */
  margin-bottom: 0;
  line-height: 24px;
  color: var(--color-dori-font-black);
}

@media (max-width: 767px) {
  #whydori .box {
    margin-bottom: 20px;
  }
  #whydori .icon {
    float: none;
    text-align: center;
    padding-bottom: 15px;
  }
  #whydori h4, #platform p {
    margin-left: 0;
    text-align: center;
  }
}

/* aboutus Section
--------------------------------*/
#aboutus {
  padding: 150px 0 0px 0;
/*background: var(--color-dori-bg);   */
  background: var(--color-dori-solutions-bg);
/*background: var(--color-dori-blue-gradient-minus45);   */
/*background: var(--color-dori-solutions-header);   */
/*background: var(--color-dori-blue);   */
  background-size: cover;
  display: flex;
  color: #eee;
}

#aboutus h1 {
  font-size: 30px;
  font-weight: 700;
  color: #eee;
}

#aboutus h2 {
  font-size: 24px;
  font-weight: 700;
  color: white;
}

#aboutus h4 {
  font-size: 16px;
  font-weight: 700;
  color: white;
}

#aboutus a {
  color: #25829a;
  transition: 0.5s;
}

#aboutus a:hover, a:active, a:focus {
  color: #1dc9ce;
  outline: none;
  text-decoration: none;
}

#aboutus #aboutus-list {
  padding: 0;
  list-style: none;
}

#aboutus #aboutus-list li {
  border-bottom: 1px solid #666;
  color: #eee;
}

#aboutus #aboutus-list a {
  padding: 18px 0;
  display: block;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  line-height: 1;
  font-weight: 300;
  padding-right: 20px;
}

#aboutus #aboutus-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 16px;
}

#aboutus #aboutus-list p {
  margin-bottom: 20px;
  margin-left: 100px;
  color: #eee
}

@media (max-width: 768px) {
  #aboutus #aboutus-list a {
    font-size: 14px;
  }
  #aboutus #aboutus-list i {
    top: 14px;
  }
}

#aboutus #aboutus-list #aboutus-header {
  color: #eee;
}
#aboutus #aboutus-list a.collapse {
  color: var(--color-dori-blue);
}

#aboutus #aboutus-list a.collapsed {
  color: #eee;
}

#aboutus #aboutus-list a.collapsed i::before {
  content: "\f2c7" !important;
}



/* Contact Section
--------------------------------*/
#contact {
  padding: 100px 0 0px 0;
/*background: var(--color-dori-bg);   */
  background: var(--color-dori-solutions-bg);
/*background: var(--color-dori-blue-gradient-minus45);   */
/*background: var(--color-dori-solutions-header);   */
/*background: var(--color-dori-blue);   */
  background-size: cover;
  display: flex;
  color: #eee;
}

#contact h1 {
  font-size: 30px;
  font-weight: 700;
  color: #eee;
}

#contact h2 {
  font-size: 24px;
  font-weight: 700;
  color: white;
}

#contact h4 {
  font-size: 16px;
  font-weight: 700;
  color: white;
}

#contact a {
  color: #25829a;
  transition: 0.5s;
}

#contact a:hover, a:active, a:focus {
  color: #1dc9ce;
  outline: none;
  text-decoration: none;
}

#contact #contact-list {
  padding: 0;
  list-style: none;
}

#contact #contact-list li {
  border-bottom: 1px solid #666;
  color: #eee;
}

#contact #contact-list a {
  padding: 18px 0;
  display: block;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  line-height: 1;
  font-weight: 300;
  padding-right: 20px;
}

#contact #contact-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 16px;
}

#contact #contact-list p {
  margin-bottom: 20px;
  margin-left: 100px;
  color: #eee
}

@media (max-width: 768px) {
  #contact #contact-list a {
    font-size: 14px;
  }
  #contact #contact-list i {
    top: 14px;
  }
}

#contact #contact-list #contact-header {
  color: #eee;
}
#contact #contact-list a.collapse {
  color: var(--color-dori-blue);
}

#contact #contact-list a.collapsed {
  color: #eee;
}

#contact #contact-list a.collapsed i::before {
  content: "\f2c7" !important;
}

#contact .form #sendmessage {
  color: var(--color-dori-blue);
  border: 1px solid var(--color-dori-blue);
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #sendmessage.show, #contact .form #errormessage.show, #contact .form .show {
  display: block;
}

#contact .form .validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 14px;
}

#contact .form input, #contact .form textarea {
  border-radius: 0;
  box-shadow: none;
  background: #f8f8f8;
  font-size: 14px;
}

#contact .form button[type="submit"] {
  background: var(--color-dori-blue);
  border: 0;
  border-radius: 5px;
  padding: 7px 30px;
  color: #fff;
  font-family: "Gill Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
}

#contact .form button[type="submit"]:hover {
  cursor: pointer;
}

#contact .box {
  padding-top: 20px;
/*margin-bottom: 30px;   */
  background: #eee;
  transition: 0.4s;
  padding: 20px;
  border: solid;
  border-radius: 10px;
  border-color: #2E5668;
  border-width: 2px;
}


/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 0;
  color: #cccccc;
  font-size: 14px;
}

#footer .credits {
  font-size: 14px;
  color: #888;
}

#footer .footer-links a {
  color: #aaa;
  padding-left: 15px;
}

#footer .footer-links a:first-child {
  padding-left: 0;
}

#footer .footer-links a:hover {
  color: var(--color-dori-blue);
}
